@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply text-lg;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --clr-text-secondary: theme(colors.gray.600);
  --clr-bg-gray: theme(colors.gray.100);
  --page-margin: theme("spacing.20");
  scroll-behavior: smooth;
  scroll-padding-top: 200px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  @apply min-h-[calc(100vh-var(--nav-height))];
}
section,
footer,
nav {
  @apply px-5 sm:px-10  lg:px-20;
}
footer * {
  @apply text-center sm:text-left;
}

.btn-primary {
  @apply bg-black/90 hover:bg-black/100 cursor-pointer select-none text-white font-semibold capitalize p-2 rounded whitespace-nowrap px-5;
}

.img-outline-right,
.img-outline-left {
  position: relative;
}

.img-outline-right::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000;
  position: absolute;
  z-index: -10;
  right: -10px;
  top: -10px;
}
.img-outline-left::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000;
  position: absolute;
  z-index: -1;
  left: -10px;
  top: -10px;
}

@keyframes slide-in {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in {
  animation: slide-in 0.7s ease-out;
}

@keyframes slide-in2 {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in2 {
  animation: slide-in2 0.7s ease-out var(--delay, 0s);
}

/* .methodology-card {
  @apply ;
} */
/* .methodology-card::before {
  @apply w-full block h-3 top-0 left-0 bg-black;
} */

/* merge style with content title */

.contact-card {
  @apply flex-1 p-3;
}

.contact-card:not(:last-child) {
  @apply border-b md:border-b-0 md:border-r border-[var(--clr-text-secondary)];
}

.contact-card-header {
  @apply flex items-center gap-2 mb-4;
}
.contact-card-header h2 {
  @apply font-bold text-lg;
}
.contact-card > p:last-child {
  @apply font-medium mt-4;
}

.form-control {
  @apply col-span-2 xl:col-span-1;
}

.form-label {
  @apply block mt-3 capitalize text-base whitespace-nowrap;
}

/* .form-label::after {
  content: "*";
  @apply text-red-700;
} */
.form-input {
  @apply outline-none border h-10 mt-3  px-3 w-full rounded text-base;
}

.error {
  @apply text-red-700 text-xs;
}

.section-title {
  @apply capitalize font-bold text-2xl text-center;
}
.section-subtitle {
  @apply font-bold text-xl capitalize;
}

/* fade into page animation by intersection observer */
.fade-in {
  transform: translateY(30%);
  opacity: 0;
  transition: transform 0.5s var(--delay, 0s), opacity 0.5s var(--delay, 0s);
}
.fade-in.show {
  transform: translateY(0);
  opacity: 1;
}

.mobile-nav.show {
  transform: translate(0);
}

input[type="submit"]:disabled {
  @apply cursor-not-allowed opacity-80 bg-black hover:bg-black;
}

